<template>
<div>
        
    <b-card
        no-body
        class="mb-0"
    >
        <div class="m-2">
             <b-row>
                <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                     <label>Show</label>
                        <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block mx-50"
                        />
                     <b-button
            variant="primary"
            :to="{ name: 'crm-template-create'}"
        >
            Add Email Template
        </b-button>
                    
                </b-col>
                <b-col
                    cols="12"
                    md="6"
                >
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input
                            v-model="searchQuery"
                            class="d-inline-block mr-1"
                            placeholder="Search..."
                        />
                        
                    </div>
                </b-col>
             </b-row>
        </div>
        <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
         <template #cell(actions)="data">
      <span>
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-item :to="{ name: 'crm-template-edit', params: { id: data.item.id } }">
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            <span>Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteconfirm(data.item.id)"> 
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span>Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </span>
        
    </template>

      </b-table>

    </b-card>



</div>
</template>
<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormCheckbox,BImg,BCardHeader,BCardBody
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useTemplateList from './useTemplateList'
//import useSendMail from './useSendMail';
import crmStoreModule from '../crmStoreModule'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
export default {
    components: {
        useTemplateList,
        crmStoreModule,
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination,BFormCheckbox,BImg,
        vSelect,
        BCardHeader,BCardBody,
        BCardActions
    },
    setup() {
         const CRM_APP_STORE_MODULE_NAME = 'app-crm';
        if (!store.hasModule(CRM_APP_STORE_MODULE_NAME)) store.registerModule(CRM_APP_STORE_MODULE_NAME, crmStoreModule);
        onUnmounted(() => {
            if (store.hasModule(CRM_APP_STORE_MODULE_NAME)) store.unregisterModule(CRM_APP_STORE_MODULE_NAME)
        })
         const {
            refListTable,
        tableColumns,
        perPage,
        totals,
        currentPage,
        perPageOptions,
        searchQuery,
        sortBy,
        deleteData,
        isSortDirDesc,
        dataMeta,
        fetchData
        } = useTemplateList()
        return {
            refListTable,
            tableColumns,
            perPage,
            totals,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            dataMeta,
            deleteData,
            fetchData
        }
        
    },
    methods:{
        deleteconfirm(id){
            this.$bvModal
                .msgBoxConfirm('Please confirm that you want to delete Email Template ID.'+id, {
                title: 'Please Confirm',
                size: 'sm',
                okVariant: 'danger',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            })
            .then(value => {
                if(value === true){
                    this.deleteData(id)
                }
               
            })
        
        }
    },
}
</script>
