import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useTemplateList() {
    const toast = useToast();
    const refListTable = ref(null);
    const tableColumns = [
        { key: 'id', label: 'ID', sortable: false },
        { key: 'title', label: 'Title', sortable: false },
        { key: 'types', label: 'Types', sortable: false },
        { key: 'actions', label: 'Action', sortable: false },
     
        
    ];
    const perPage = ref(10);
    const totals = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(true);
    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
            return {
                from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
                to: perPage.value * (currentPage.value - 1) + localItemsCount,
                of: totals.value,
            }
    });
    const refetchData = () => {
    
        refListTable.value.refresh()
    };
    watch([currentPage, perPage, searchQuery], () => {
      
        refetchData()
    })
    const deleteData = (id) => {
        //fetchDeleteProperty
        store.dispatch('app-crm/fetchDelete',id).then(response=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Delete Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData();
        }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Delete Blog",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        //console.log('id', id);
        
      }
    const fetchData = (ctx, callback)=>{
        const offset = perPage.value*(currentPage.value-1);
       
        store
        .dispatch('app-crm/fetchtemplateData', {
            perpage: perPage.value,
            offset:offset,
         
         
        })
        .then(response => {
            
            
            const data = response.data.data;
         
            totals.value = response.data.total
        //  const { invoices, total } = response.data
            callback(data)
            // callback(invoices)
            //totalInvoices.value = total
        })
        .catch(() => {
            toast({
            component: ToastificationContent,
            props: {
                title: "Error fetching Owner' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
            })
        })
    }
    return {
        refListTable,
        tableColumns,
        perPage,
        totals,
        currentPage,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        dataMeta,
        fetchData,
        deleteData
        
    }
}