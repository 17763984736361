import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        
        fetchData(ctx, queryParams){
            var data = JSON.stringify(queryParams);
            return new Promise((resolve, reject) => {
                axios
                .post('/users/v1/sendlists/'+queryParams.groupQuery, data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        addSubscriber(ctx, queryParams){
            var data = JSON.stringify(queryParams);
            return new Promise((resolve, reject) => {
                axios
                .post('/crm/v1/subscriber-add', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        updateSubscriber(ctx, queryParams){
            var data = JSON.stringify(queryParams);
            return new Promise((resolve, reject) => {
                axios
                .put('/crm/v1/subscriber/update/'+queryParams.id, data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        deleteSubscriber(ctx,id){
            
              
            return new Promise((resolve, reject) => {
              axios
                .delete('/crm/v1/subscriber/delete/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
  
        },
        
        fetchcampaignUserData(ctx, queryParams){

            var data = JSON.stringify(queryParams);
           
            return new Promise((resolve, reject) => {
                axios
                .post('/crm/v1/campaign-user-list', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchcampaignData(ctx, queryParams){
            var data = JSON.stringify(queryParams);
           
            return new Promise((resolve, reject) => {
                axios
                .post('/crm/v1/campaign-list', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
            

        },
       
        fetchsubscriberData(ctx, queryParams){
            var data = JSON.stringify(queryParams);
           
            return new Promise((resolve, reject) => {
                axios
                .post('/crm/v1/subscriber-list', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
            

        },
        fetchsubscriberUpdatePublicData(ctx, queryParams){
            var data = JSON.stringify(queryParams);
            console.log('queryParams',queryParams);
            return new Promise((resolve, reject) => {
                axios
                .put('/crm/v1/subscriber-update-public/'+queryParams.id, data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
            
0
        },
        fetchsubscriberDetailData(ctx, queryParams){
            console.log('mail-queryParams', queryParams);
            return new Promise((resolve, reject) => {
                axios
                .put('/crm/v1/subscriber/'+queryParams.id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
            
0
        },
        fetchtemplateData(ctx, queryParams){
            var data = JSON.stringify(queryParams);
            return new Promise((resolve, reject) => {
                axios
                .post('/crm/v1/template-list', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
        fetchGroupMail(ctx, queryParams){

            var data = JSON.stringify(queryParams);
      
            return new Promise((resolve, reject) => {
                
                axios
                .post('groupmail/v1/lists', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        
        },
        fetchCampaignDelete(ctx,id){
            return new Promise((resolve, reject) => {
                axios
                .delete('/crm/v1/delete-campaign/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchDelete(ctx,id){
            return new Promise((resolve, reject) => {
                axios
                .delete('/crm/v1/delete-template/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        }
        
    }
}